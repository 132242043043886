<template>
  <div class="app">
    <Navbar
      :title="
        verConfig.needBank
          ? $t('newAdd.AddBankCardTitle')
          : $t('newAdd.AddWalletTitle')
      "
    />
    <!-- <van-nav-bar
      :fixed="true"
      left-arrow
      placeholder
      @click-left="onClickLeft"
      :title="
        verConfig.needBank
          ? $t('newAdd.AddBankCardTitle')
          : $t('newAdd.AddWalletTitle')
      "
    /> -->
    <!-- <van-tabs
      v-model="bankType"
      @change="typeChange"
      type="card"
      class="tabs_wrap"
      v-if="verConfig.needBank"
      color="var(--theme,#ee0a24)"
    >
      <van-tab :title="$t('newAdd.cardTabBank')" name="bank"></van-tab>
      <van-tab :title="$t('newAdd.cardTabUSDT')" name="coin"></van-tab>
    </van-tabs> -->
    <div class="list" pullTips="false">
      <div class="list-space"></div>

      <van-field
        v-if="bankType === 'bank'"
        :hideDoneButton="false"
        v-model="formData[bankType].name"
        :label="bankType === 'coin' ? $t('newAdd.name') : $t('newAdd.RealName')"
        :placeholder="$t('newAdd.RealName')"
      />
      <van-field
        v-else
        :hideDoneButton="false"
        v-model="paymentType"
        disabled
        :label="bankType === 'coin' ? 'Payment type' : $t('newAdd.RealName')"
      />
      <div class="list-line" v-if="bankType === 'coin'"></div>

      <van-field
        v-if="bankType === 'coin'"
        :hideDoneButton="false"
        v-model="bank"
        disabled
        :label="$t('withdraw.currency.label')"
        :placeholder="$t('withdraw.currency.label')"
      />
      <div class="list-line"></div>
      <!-- ifsc的一行 -->
      <!-- <div class="list-item">
        <span class="list-item-title">{{ $t("newAdd.IFSCCODE") }}</span>
        <input
          :hideDoneButton="false"
          class="list-item-input"
          v-model="ifsc_code"
          :placeholder="$t('newAdd.IFSCCODE')"
        />
      </div>
      <div class="list-line"></div> -->
      <!-- <div class="list-item">
        language-ch 银行名称-以前的注释
        <span class="list-item-title">{{ $t("newAdd.BankName") }}</span>
        language-ch 请输入银行名称-以前的注释
        <input
          :hideDoneButton="false"
          class="list-item-input"
          v-model="input_bank"
          :placeholder="$t('newAdd.BankName')"
        />
      </div> -->
      <van-field
        v-if="bankType === 'bank' && !!verConfig.banks"
        v-model="bankshowVal"
        is-link
        readonly
        :label="$t('newAdd.BankName')"
        :placeholder="$t('newAdd.BankName')"
        @click="show = true"
      />
      <van-field
        v-if="bankType === 'bank' && !verConfig.banks"
        v-model="formData[bankType].bank"
        :label="$t('newAdd.BankName')"
        :placeholder="$t('newAdd.BankName')"
      />
      <van-popup
        v-if="!!verConfig.banks"
        v-model="show"
        round
        position="bottom"
      >
        <van-cascader
          v-model="formData[bankType].bank"
          :title="$t('newAdd.BankName')"
          :options="mxhBankArr"
          v-if="country != 'tr'"
          :placeholder="$t('newAdd.BankName')"
          @change="cascaderChange"
          @close="show = false"
        />
        <van-cascader
          v-model="formData[bankType].bank"
          :title="$t('newAdd.BankName')"
          :options="trBankArr"
          v-if="country == 'tr'"
          active-color="var(--theme)"
          :placeholder="$t('newAdd.BankName')"
          @change="cascaderChange"
          @close="show = false"
        />
      </van-popup>

      <!--  -->
      <van-field
        v-if="bankType === 'coin'"
        v-model="coinTypeShow"
        is-link
        readonly
        :label="$t('newAdd.chainType')"
        :placeholder="$t('newAdd.chainType')"
        @click="coinCascader = true"
      />
      <van-popup v-model="coinCascader" round position="bottom">
        <van-cascader
          v-model="formData[bankType].open"
          :title="$t('newAdd.chainType')"
          :options="coinTypeArr"
          :placeholder="$t('newAdd.chainType')"
          @change="coinTypeChange"
          @close="coinCascader = false"
        />
      </van-popup>
      <div class="list-line"></div>
      <van-field
        :hideDoneButton="false"
        v-model="formData[bankType].card"
        :label="
          bankType === 'coin'
            ? $t('newAdd.depositAddress')
            : $t('newAdd.BankNum')
        "
        :placeholder="
          bankType === 'coin'
            ? $t('newAdd.depositAddress')
            : $t('newAdd.BankNum')
        "
        onkeyup="value=value.replace(/[\W]/g,'')"
        onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))"
      />
      <div class="list-line"></div>
      <!-- <div class="list-item">
        <span class="list-item-title">{{ $t("newAdd.BankNum") }}</span>
        <input
          :hideDoneButton="false"
          class="list-item-input"
          v-model="input_card"
          :placeholder="$t('newAdd.BankNum')"
          type="text"
          onkeyup="value=value.replace(/[\W]/g,'')"
          onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))"
        />
      </div> -->
      <!-- 输入邮箱 -->

      <!-- <div class="list-line"></div> -->
      <!-- <div class="list-item">
        language-ch 开户支行
        <text class="list-item-title">Account opening branch</text>
        language-ch 请输入开户支行
        <input
          :hideDoneButton="false"
          class="list-item-input"
          v-model="input_open"
          placeholder="Please enter the opening branch"
        />
      </div> -->
      <!-- <div class="list-line"></div>
      <div class="list-item">
        <text class="list-item-title">UPI</text>
        <input
          :hideDoneButton="false"
          class="list-item-input"
          v-model="upi"
          placeholder="UPI/BANK ACCOUNT NUMBER"
        />
      </div> -->

      <!-- <van-field
        v-if="bankType === 'bank'"
        :hideDoneButton="false"
        v-model="formData[bankType].mobile"
        :label="$t('newAdd.Mobile')"
        :placeholder="$t('newAdd.MobileNum')"
      />
      <div class="list-line"></div> -->
      <!-- <div class="list-item">
        <span class="list-item-title">{{ $t("newAdd.Mobile") }}</span>
        <input
          :hideDoneButton="false"
          class="list-item-input"
          v-model="mobile"
          :placeholder="$t('newAdd.MobileNum')"
        />
      </div> -->

      <!-- 邮件的一行 -->
      <!-- <van-field
        :hideDoneButton="false"
        v-if="bankType === 'bank'"
        v-model="formData[bankType].email"
        :label="$t('newAdd.Email')"
        :placeholder="$t('newAdd.EmailRequired')"
      /> -->
      <!-- <div class="list-item">
        <span class="list-item-title">{{ $t("newAdd.Email") }}</span>
        <input
          :hideDoneButton="false"
          class="list-item-input"
          v-model="email"
          :placeholder="$t('newAdd.EmailRequired')"
        />
      </div> -->

      <div>
        <!-- language-ch 注意：银行卡信息为重要信息，添加后禁止修改！ -->
        <span class="list-red">
          {{
            verConfig.needBank
              ? $t("newAdd.AddBankCardNote")
              : $t("newAdd.AddWalletNote")
          }}
        </span>
      </div>
      <!-- language-ch 提 交 -->
      <button @click="itemClick()" class="button">
        {{ $t("newAdd.Submit") }}
      </button>
    </div>
  </div>
</template>



<script>
import { GET_USER_BANK_INFO, COMMIT_USER_BANK_INFO } from "@/api";
import { count } from "@/utils";
import Cookies from "js-cookie";
import { NavBar, Dialog, Toast, Cascader, Popup, Field, Tab, Tabs } from "vant";
import "vant/lib/button/style";
import verConfig from "@/utils/verConfig";
import Navbar from "@/components/JNav";
export default {
  components: {
    [NavBar.name]: NavBar,
    [Dialog.Component.name]: Dialog.Component,
    [Toast.name]: Toast,
    [Cascader.name]: Cascader,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    Navbar,
  },
  data() {
    this.paymentType = "cryptocurrency";
    this.bank = "USDT";
    return {
      show: false,
      country: Cookies.get("language"),
      bankshowVal: "",
      dataid: 0,
      input_name: "",
      input_bank: "",
      input_card: "",
      input_open: "",
      mobile: "",
      upi: "",
      email: "",
      ifsc_code: "",
      bankType: verConfig.needBank ? "bank" : "coin",
      bankArr: [
        {
          value: "IDPT0001",
          text: "Canara Bank",
        },
        {
          value: "IDPT0002",
          text: "	DCB Bank",
        },
        {
          value: "IDPT0003",
          text: "	Federal Bank",
        },
        {
          value: "IDPT0004",
          text: "HDFC Bank",
        },
        {
          value: "IDPT0005",
          text: "Punjab National Bank",
        },
        {
          value: "IDPT0006",
          text: "Indian Bank",
        },
        {
          value: "IDPT0007",
          text: "ICICI Bank",
        },
        {
          value: "IDPT0008",
          text: "Syndicate Bank",
        },
        {
          value: "IDPT0009",
          text: "Karur Vysya Bank",
        },
        {
          value: "IDPT0010",
          text: "Union Bank of India",
        },
        {
          value: "IDPT0011",
          text: "Kotak Mahindra Bank",
        },
        {
          value: "IDPT0012",
          text: "IDFC First Bank",
        },
        {
          value: "IDPT0013",
          text: "Andhra Bank",
        },
        {
          value: "IDPT0014",
          text: "Karnataka Bank",
        },
        {
          value: "IDPT0015",
          text: "icici corporate bank",
        },
        {
          value: "IDPT0016",
          text: "Axis Bank",
        },
        {
          value: "IDPT0017",
          text: "UCO Bank",
        },
        {
          value: "IDPT0018",
          text: "South Indian Bank",
        },
        {
          value: "IDPT0019",
          text: "Yes Bank",
        },
        {
          value: "IDPT0020",
          text: "Standard Chartered Bank",
        },
        {
          value: "IDPT0021",
          text: "State Bank of India",
        },
        {
          value: "IDPT0022",
          text: "Indian Overseas Bank",
        },
        {
          value: "IDPT0023",
          text: "Bandhan Bank",
        },
        {
          value: "IDPT0024",
          text: "Central Bank of India",
        },
        {
          value: "IDPT0025",
          text: "Bank of Baroda",
        },
      ],
      trBankArr: [
        {
          value: "Garanti-Bank",
          text: "Garanti Bankası",
        },
        {
          value: "is-Bank",
          text: "İş Bankası",
        },
        {
          value: "Vakifbank",
          text: "Vakıfbank",
        },
        {
          value: "Halk-Bank",
          text: "Halk Bankası",
        },
        {
          value: "Denizbank",
          text: "Denizbank",
        },
        {
          value: "Akbank",
          text: "Akbank",
        },
        {
          value: "Turkiye-Finans",
          text: "Türkiye Finans Bank",
        },
        {
          value: "INGBank",
          text: "INGBank",
        },
        {
          value: "Kuveytturk",
          text: "Kuveyttürk",
        },
        {
          value: "Ziraat-Bank",
          text: "Ziraat Bankası",
        },
        {
          value: "Yapi-Kredi",
          text: "Yapı Kredi",
        },
        {
          value: "Sekerbank",
          text: "Şekerbank",
        },
        {
          value: "Albaraka",
          text: "Albaraka",
        },
        {
          value: "Aktif-Bank",
          text: "Aktif Bank",
        },
      ],
      mxhBankArr: verConfig.banks || [],
      coinTypeArr: [{ value: "TRC20", text: "TRC20" }],
      coinTypeShow: "",
      input_coin_type: "",
      coinCascader: false,
      bankData: {},
      coinData: {},
      formData: {
        bank: {},
        coin: {},
      },
      walletID: "",
    };
  },
  mounted() {
    const { id } = this.$route.query;
    if (!!id) {
      this.walletID = id;
    }
    this.getUserBankInfo();
  },
  methods: {
    formDataInit() {
      if (this.bankType === "bank") {
        this.$map.call(
          this.country === "tr" ? this.trBankArr : this.mxhBankArr,
          (item) => {
            if (this.formData.bank.bank === item.value) {
              this.bankshowVal = item.text;
            }
          }
        );
      } else {
        this.$map.call(this.coinTypeArr, (item) => {
          if (this.formData.coin.open === item.value)
            return (this.coinTypeShow = item.text);
        });
      }
    },
    coinTypeChange({ selectedOptions, value }) {
      this.coinCascader = false;
      this.coinTypeShow = selectedOptions[0].text;
    },
    cascaderChange(item) {
      this.show = false;
      this.bankshowVal = item.selectedOptions[0].text;
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    typeChange(name, title) {
      this.formDataInit();
      if (name === "coin") {
        this.formData.coin.bank = "USDT";
      }
    },
    itemClick() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      this.formData.coin.bank = "USDT";
      COMMIT_USER_BANK_INFO(this.formData[this.bankType])
        .then((r) => {
          Toast.clear();
          if (r.data.ret === 1) {
            Toast.clear();
            Dialog.alert({
              title: this.$t("newAdd.warmTitle"),
              message: r.data.msg,
              confirmButtonText: this.$t("My.Button.Two"),
              cancelButtonText: this.$t("My.Button.One"),
            }).then(() => {
              const { data } = r.data;
              this.$cookie.set("selectBankInfo", JSON.stringify(data));
              this.$router.go(-1);
            });
          } else {
            Toast.clear();
            Toast(r.data.msg);
          }
        })
        .catch((e) => {
          Toast.clear();
          console.error(e);
        });
    },
    getUserBankInfo() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      GET_USER_BANK_INFO()
        .then((r) => {
          Toast.clear();
          const { data } = r.data;
          if (r.data.ret === 1 && data.length > 0) {
            Toast.clear();
            const params = this.$route.query;
            const bankId = params.id || data[0].id;
            this.$map.call(data, (item, index) => {
              if (bankId == item.id) {
                if (!!item.open && item.open !== "bank") {
                  this.bankType = "coin";
                } else {
                  this.bankType = "bank";
                }
              }
              if (!!item.open && item.open !== "bank") {
                this.formData.coin = item;
                this.formDataInit();
              } else {
                this.formData.bank = item;
                this.formDataInit();
              }
              return;
            });
          }
        })
        .catch((e) => {
          Toast.clear();
          console.error(e);
        });
    },
  },
};
</script>

<style scoped lang="less">
@wrapPadding: calc(12rem / 16);
@comRadius: 10px;
// @comBG: #07304a;
@comBG: var(--bg1);
.app {
  flex: 1;
  width: 100%;
  min-height: 100vh;
  // background-color: #f4f8f9;
  background: var(--bg);
  /deep/ .van-tabs__wrap {
    @height: calc(50rem / 16);
    height: @height;
    margin: 6px @wrapPadding 0;
    // .van-tabs__nav,
    .van-tabs__nav--card {
      background: var(--bg);
      margin: 0;
      border: none;
      height: @height;
      .van-tab {
        margin-left: @wrapPadding;
        width: calc((100% - @wrapPadding) / 2);
        background: #2a68e8;
        color: var(--textColor);
        border: none;
        border-radius: @comRadius;
        &:nth-child(1) {
          margin-left: 0;
        }
      }
      .van-tab--active {
        background: #0094ac;
        border: none;
        color: var(--textColor);
      }
    }
  }
  /deep/ .van-cascader {
    background: @comBG;
    .van-tabs__wrap,
    .van-tabs__nav {
      background: @comBG;
    }
    .van-cascader__title {
      color: var(--textColor);
    }
    .van-cascader__tab {
      color: var(--textColor);
    }
    .van-tab--active {
      color: var(--theme);
    }
    .van-tabs__line {
      background: var(--theme);
    }
    .van-cascader__option--selected {
      color: var(--theme);
    }
    .van-cascader__option:active {
      background: var(--theme);
      color: var(--textColor);
    }
  }

  // /deep/ .van-tabs__line {
  //   background: var(--theme, #ee0a24);
  // }

  /deep/ .van-field__label {
    width: fit-content;
    color: var(--gray1);
  }

  /deep/ .van-field--disabled .van-field__label {
    // color: #646566;
    color: var(--gray1);
  }

  /deep/ .van-field__control:disabled {
    color: var(--textColor);
    -webkit-text-fill-color: var(--textColor);
  }
  /deep/ .van-field__control {
    color: var(--textColor);
  }
  /deep/ .van-cell {
    background: @comBG;
    &::after {
      border: none;
    }
  }

  .tabs_wrap {
    margin-top: calc(8rem / 16);
    // /deep/ .van-tabs__nav--card {
    //   border-color: #ff0000;
    // }
    // /deep/ .van-tabs__nav--card .van-tab.van-tab--active {
    //   background-color: #ff0000;
    // }
  }

  > .title {
    height: calc(49rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffba04;
    color: #464646;
    font-weight: 400;
    font-size: calc(18rem / 16);
    position: relative;
    width: 100%;
    margin-top: 0;

    > .explain-box {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: calc(16rem / 16);
      position: absolute;
      top: 50%;
      right: calc(16rem / 16);
      transform: translate(0, -50%);
      color: var(--textColor);

      > p {
        // padding-bottom: calc(4rem / 16);
        margin-left: calc(3rem / 16);
      }
    }
  }
}

.list {
  flex: 1;
  width: 100%;
}

.list-space {
  width: 100%;
  height: calc(10rem / 16);
  // background-color: #f4f8f9;
}

.list-item {
  width: 100%;
  height: calc(50rem / 16);
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
}

.list-item-title {
  font-size: calc(14rem / 16);
  margin-left: calc(12rem / 16);
  margin-right: calc(17rem / 16);
  color: #242424;
}

.list-item-input {
  flex: 1;
  font-size: calc(14rem / 16);
  height: calc(40rem / 16);
  padding-left: calc(12rem / 16);
  border: none;
}

.list-red {
  margin-top: calc(62rem / 16);
  // color: #dc1323;
  color: var(--theme);
  font-size: calc(14rem / 16);
  text-align: center;
  display: block;
}

.list-line {
  width: 100%;
  height: 1px;
  // background-color: #ececec;
  // background: var(--gray1);
  background-color: var(--borderColor);
}

.button {
  width: calc(340rem / 16);
  height: calc(43rem / 16);
  line-height: calc(43rem / 16);
  font-size: calc(14rem / 16);
  margin: 0 auto;
  margin-top: calc(11rem / 16);
  // margin-left: calc(17rem / 16);
  // background-color: #dc1323;
  background-color: var(--theme);
  border-radius: calc(8rem / 16);
  // color: var(--textColor);
  color: var(--light);
  border: none;
  display: block;
}
</style>